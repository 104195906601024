.play-pause {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: none;
  background: transparent;
  font-size: 0;
  padding: 0;
  cursor: pointer;
}

.play-pause:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.play-pause__icon--is-hidden {
  display: none;
}