@mixin hover {
  @media (hover: hover) {
    &:hover {
      @content;
    }
  }
  &:active {
    @content;
  }
}
