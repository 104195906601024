.pop-in {
  position: fixed;
  width: 4.375rem;
  height: 7.75rem;
  background: $red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  will-change: transform;
  transition: transform 0.5s ease-in-out;
  color: $white;
  border-radius: 4px;
}

.pop-in--right {
  bottom: 1rem;
  right: 1rem;
  transform: translateX(125%);
}

.pop-in--left {
  bottom: 4rem;
  left: 1rem;
  transform: translateX(-125%);
}

.pop-in--is-visible {
  transform: translateX(0);
}

.pop-in--film {
  width: 9.625rem;
  height: 4.75rem;
  padding: 0.5rem;
}

.pop-in--film video {
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: calc(100% - 1rem);
  height: calc(100% - 1rem);
  object-fit: cover;
}

.pop-in__swoosh {
  margin-bottom: 1rem;
}

.pop-in {
  @include hover {
    .pop-in__swoosh {
      transform: scaleX(-1);
    }
  }
}

.pop-in__buy-link {
  font-size: 0.875rem;
  border-radius: 111px;
  background: $blue;
  padding: 0 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.75rem;
  line-height: 1em;
}

// expand link area over whole parent element without having to wrap whole thing in a link
.pop-in__buy-link:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pop-in__label {
  margin-top: 1rem;
  display: block;
  text-align: center;
  color: $white
}

.pop-in__label--center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 0;
}

.pop-in__label--is-hidden {
  display: none;
}

.pop-in__scroll-icon--mouse {
  display: none;

  @media (hover: hover) {
    display: block;
  }
}

@keyframes wheel {
  0% {
    opacity: 0;
    transform: translateY(-4px);
  }
  10% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(12px);
  }
}

.pop-in__scroll-icon--mouse circle {
  animation: wheel 1.7s linear infinite;
}

.pop-in__scroll-icon--hand {
  display: none;

  @media (hover: none) {
    display: block;
  }
}

@keyframes touchmove {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  10% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(-8px);
  }
}

.pop-in__scroll-icon--hand {
  animation: touchmove 1.7s linear infinite;
}