// Core
@import "variables";
@import "functions";
@import "mixins";
@import "utilities";

// Reboot
@import "reboot";

// CSS-Vars
@import "root";

// Helpers
@import "helpers";

// Utilities
@import "utilities/api";

// Typography
@import "type";

// Animations
@import "animations";

// Elements
@import "elements";

// Components
@import "components";

body,
html {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overscroll-behavior-x: none;
  touch-action: none;
  overflow: hidden;
}

.desktop-only {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.mobile-only {
  @media (min-width: 769px) {
    display: none !important;
  }
}

.caption {
  position: fixed;
  bottom: 10px;
  left: 10px;
  right: 10px;
  margin: 0;
  text-align: center;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 24px;
  text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.switch-views {
  position: fixed;
  bottom: 6px;
  right: 6px;
  width: 15vw;
  height: 15vh;
  background: rgba(#fff, 0);
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.unmute {
  position: fixed;
  bottom: 10px;
  left: 10px;
}

body.hide-pointer * {
  cursor: none !important;
}