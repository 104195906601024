.intro-view {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: $black;

  &__container {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__main {
    position: relative;
    z-index: 10;
    font-size: 0;
    margin-bottom: 4rem;
  }

  &__title {
    user-select: none;
    svg {
      width: 360px;
      @include media-breakpoint-down("xs") {
        width: 260px;
      }
    }
  }

  &__subtitle {
    @include typo--subtitle;
    user-select: none;
    margin-bottom: 0.5rem;
  }

  &__ui {
    margin: 2.25rem auto;
    height: 4.8rem;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media-breakpoint-down("sm") {
      margin: 0.5rem auto;
    }

    @include media-breakpoint-down("xxs") {
      margin: 0rem auto;
    }
  }
}

.intro-view__main svg {
  @include media-breakpoint-down("xxs") {
    width: 70px;
    height: auto;
  }
}

.intro-video {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  background-color: $black;
  opacity: 0.8;
}

.progress {
  z-index: 0; // safari overflow hidden bug;
  background-color: rgba($white, 0.25);
  height: 4px;
  position: relative;
  width: 247px;
  overflow: hidden;
  border-radius: 6px;
}

.progress-bar {
  animation-duration: 2s;
  animation-name: progress;
  animation-iteration-count: infinite;
  background-color: $white;
  height: 100%;
  width: 100%;
  position: relative;
  will-change: transform;
}

.suggestions {
  position: fixed;
  width: 100%;
  bottom: 4.75rem;
  padding: 0 1rem;
  text-align: center;
  pointer-events: none;
  z-index: 100;
  @include typo--audio-subtitle;

  @include media-breakpoint-down("md") {
    bottom: 1rem;
  }
}

@keyframes progress {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
