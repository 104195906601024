.subtitles {
  position: fixed;
  bottom: 3rem;
  width: 100%;
  padding: 0 1rem;
  text-align: center;
  pointer-events: none;
}

.subtitles__subtitle {
  @include typo--audio-subtitle;
  max-width: 40em;
  display: block;
  margin: auto;
}
