// Options
$enable-rounded: false !default;
$enable-shadows: false !default;
$enable-negative-margins: false !default;
$enable-important-utilities: true !default;

// Color system
$white: #fff !default;
$black: #000 !default;
$grey: #505050 !default;
$primary: blue !default;
$info: #0066ff !default;
$success: #e07369 !default;
$warning: #ffaa2b !default;
$danger: #ff0000 !default;
$red: #ec2f1d !default;
$blue: #003da6 !default;

$theme-colors: (
  "white": $white,
  "black": $black,
  "grey": $grey,
  "primary": $primary,
  "info": $info,
  "success": $success,
  "warning": $warning,
  "danger": $danger,
) !default;

$theme-colors-variants: (
  "primary": (
    "hover": lighten(map-get($theme-colors, "primary"), 10%),
    "active": darken(map-get($theme-colors, "primary"), 10%),
  ),
) !default;

$theme-colors-shades: (
  "darken": 10,
  "lighten": 10,
) !default;

// Body
$body-bg: $black !default;
$body-color: $white !default;

// Spacing
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer / 2,
  2: $spacer,
  3: $spacer * 1.5,
  4: $spacer * 2,
  5: $spacer * 2.5,
  6: $spacer * 3,
  7: $spacer * 3.5,
  8: $spacer * 4,
  9: $spacer * 4.5,
  10: $spacer * 5,
  11: $spacer * 5.5,
  12: $spacer * 6,
  13: $spacer * 6.5,
  14: $spacer * 7,
  15: $spacer * 7.5,
  16: $spacer * 8,
  17: $spacer * 8.5,
  18: $spacer * 9,
  19: $spacer * 9.5,
  20: $spacer * 10,
  21: $spacer * 10.5,
  22: $spacer * 11,
  23: $spacer * 11.5,
  24: $spacer * 12,
) !default;

$negative-spacers: if(
  $enable-negative-margins,
  negativify-map($spacers),
  null
) !default;

// Breakpoints
$breakpoints: (
  xxs: 0px,
  xs: 321px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
) !default;

// Components
$border-width: 1px !default;
$border-color: lighten($black, 80%) !default;

$border-radius: 0.25rem !default;
$border-radius-sm: 0.2rem !default;
$border-radius-lg: 0.3rem !default;

$rounded-pill: 50rem !default;

$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "Neue Haas Grotesk Display", Helvetica, Arial,
  sans-serif !default;
$font-family-monospace: "M+1m", Menlo, Monaco, "Courier New", monospace !default;
$font-family-base: $font-family-monospace !default;
$font-family-text: "Neue Haas Grotesk Text", Helvetica, Arial, sans-serif !default;

// $font-size-root effects the value of `rem`, which is used for as well font sizes, paddings and margins
// $font-size-base effects the font size of the body text
$font-size-root: null !default;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$line-height-base: 1.4 !default;
$letter-spacing-base: normal !default;

// Z-index master list
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-high: 120 !default;
$zindex-medium: 110 !default;
$zindex-low: 100 !default;
